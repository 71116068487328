import React from 'react';
import {
  QueryDocumentSnapshot,
} from 'firebase/firestore';

import { Group, GroupMember, InvitedGroupMember } from './common/firestoreTypes';
import UserInfo from './UserInfo';
import { useGroups } from './groupsModel';
import { Link } from 'react-router-dom';

type UserGroupListProps = {
  uid: string,
  showHeader: boolean,
}

export default function UserGroupList({
  uid,
  showHeader,
}: UserGroupListProps) {
  const groups = useGroups(uid);

  if (!groups) {
    return null;
  }

  const groupMember = (member: GroupMember | InvitedGroupMember) => {
    if ('uid' in member && member.uid === uid) {
      return null;
    }
    return (
      <UserInfo
        key={('uid' in member) ? member.uid : member.email}
        user={member}
      />
    );
  };

  const groupListing = (groupSnap: QueryDocumentSnapshot<Group>) => {
    const group = groupSnap.data();
    return (
      <tr
        key={groupSnap.id}
        className="border-y py-2"
      >
        <td
          style={{
            maxWidth: '50vw',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <b>
            { group.status !== 'active' && (
              <b>
                [{ group.status }]
              </b>
            ) }
            <Link to={`/group/${groupSnap.id}`}>
              { group.name }
            </Link>
          </b>
        </td>
        <td className="pl-2">
          { group.members.map(groupMember) }
        </td>
      </tr>
    );
  };

  return (
    <div className="w-full overflow-hidden">
      { showHeader && (<h2>Your connections:</h2>) }
      { groups.docs.length === 0 && 'None yet'}
      <table>
        <tbody>
          { groups.docs.map(groupListing) }
        </tbody>
      </table>
      { showHeader && (
        <div>
          <br />
          <Link to="/newGroup">Invite a friend</Link>
        </div>
      )}
    </div>
  );
}
