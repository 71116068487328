import { WeeklyTime, WeeklyTimeRange } from './firestoreTypes';

export const msPerSecond = 1000;
export const msPerMinute = 60 * msPerSecond;
export const msPerHour = 60 * msPerMinute;
export const msPerDay = 24 * msPerHour;
export const msPerWeek = 7 * msPerDay;
export const minWeeklyTime = 0;
export const maxWeeklyTime = msPerWeek - msPerMinute; // Sat 11:59pm

export const shortDayString = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

// Converts a WeeklyTime to number of milliseconds since the beginning of the
// week. Ignores DST changes because this has no idea what week it is!
export function weeklyTimeToScalar({ day, hour, minute }: WeeklyTime) {
  return 1000 * 60 * (minute + (60 * (hour + (24 * day))));
}

// Converts milliseconds since beginning of week into a WeeklyTime.
export function weeklyTimeFromScalar(scalar: number): WeeklyTime {
  const minute = Math.trunc((scalar % msPerHour) / msPerMinute);
  const hour = Math.trunc((scalar % msPerDay) / msPerHour);
  const day = Math.trunc(scalar / msPerDay);
  return { day, hour, minute };
}

// Compares two WeeklyTimes for sorting purposes.
export function weeklyTimeCompare(a: WeeklyTime, b: WeeklyTime) {
  return weeklyTimeToScalar(a) - weeklyTimeToScalar(b);
}

// Returns the duration of a WeeklyTimeRange in milliseconds.
export function weeklyTimeRangeDuration(range: WeeklyTimeRange) {
  const result = weeklyTimeToScalar(range.end) - weeklyTimeToScalar(range.start);
  if (result < 0) {
    return result + msPerWeek;
  }
  return result;
}

export function weeklyTimeRangeDurationString(range: WeeklyTimeRange) {
  const dur = weeklyTimeRangeDuration(range);
  const hours = Math.floor(dur / msPerHour);
  const minutes = Math.floor((dur - (hours * msPerHour)) / msPerMinute);
  const results = [];
  if (hours) {
    results.push(`${hours} hour${hours === 1 ? '' : 's'}`);
  }
  if (minutes) {
    results.push(`${minutes} minute${minutes === 1 ? '' : 's'}`);
  }
  if (results.length === 0) {
    return 'Zero time';
  }
  return results.join(' ');
}

// Returns the WeeklyTime that is duration milliseconds later than weeklyTime.
// But clamps the result to the range [minWeeklyTime, maxWeeklyTime]. The
// duration can be negative.
export function weeklyTimePlus(weeklyTime: WeeklyTime, duration: number) {
  const result = Math.max(
    minWeeklyTime,
    Math.min(
      maxWeeklyTime,
      weeklyTimeToScalar(weeklyTime) + duration,
    ),
  );
  return weeklyTimeFromScalar(result);
}

// Returns the earlier of two WeeklyTimes.
export function weeklyTimeMin(a: WeeklyTime, b: WeeklyTime) {
  return weeklyTimeFromScalar(
    Math.min(
      weeklyTimeToScalar(a),
      weeklyTimeToScalar(b),
    ),
  );
}

// Returns the later of two WeeklyTimes.
export function weeklyTimeMax(a: WeeklyTime, b: WeeklyTime) {
  return weeklyTimeFromScalar(
    Math.max(
      weeklyTimeToScalar(a),
      weeklyTimeToScalar(b),
    ),
  );
}

export type TimeRange = { start: Date, end: Date };
