type ScopeDescription = {
  name: string,
  reason: string,
};

export const requiredScopesReasons: { [scope: string]: ScopeDescription } = {
  'https://www.googleapis.com/auth/calendar.calendarlist.readonly': {
    name: 'See the list of Google Calendars you\'re subscribed to',
    reason: `Friend Scheduler needs to be able to find your calendars
    so it can check your availability.`,
  },
  'https://www.googleapis.com/auth/calendar.freebusy': {
    name: 'View your availability in your calendars',
    reason: 'Friend Scheduler needs to be able to see when you\'re free.',
  },
};

export const requiredScopes = Object.keys(requiredScopesReasons);

export const optionalScopesReasons: { [scope: string]: ScopeDescription } = {
  'https://www.googleapis.com/auth/calendar.app.created': {
    name: 'Make secondary Google calendars, and see, create, change, and delete events on them',
    reason: `If you allow this optional permission, Friend Scheduler can create an events for
    you directly in Google Calendar. It still will not be able to see events in your
    other calendars, except whether you're free or busy. If you don't grant this permission,
    it will show you an "Add to Calendar" button for each event. `,
  },
};

export const optionalScopes = Object.keys(optionalScopesReasons);

export const allScopes = requiredScopes.concat(optionalScopes);
