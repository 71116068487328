import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  connectAuthEmulator,
  getAuth,
} from 'firebase/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
} from 'firebase/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
} from 'firebase/functions';
import {
  AuthProvider,
  FirestoreProvider,
  FunctionsProvider,
  useFirebaseApp,
} from 'reactfire';

import AppRoutes from './AppRoutes';
import './App.css';

function App() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  // initialize Database and Auth with the normal Firebase SDK functions
  const firestoreInstance = getFirestore(app);
  const auth = getAuth(app);
  const functions = getFunctions();

  if (process.env.REACT_APP_USE_EMULATOR) {
    // Set up emulators
    connectFirestoreEmulator(firestoreInstance, 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  // ReactFire providers - provide Firebase services for all components
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <FunctionsProvider sdk={functions}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </FunctionsProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
