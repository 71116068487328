import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { Link, useSearchParams } from 'react-router-dom';

import Loading from '../Loading';
import {
  ListUsersRequest,
  ListUsersResponse,
} from '../common/functionsTypes';
import { showError } from '../modal';

export default function AllUsers() {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageToken = searchParams.get('page');
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersList, setUsersList] = useState<ListUsersResponse>({ users: [] });
  const functions = useFunctions();

  useEffect(() => {
    const getUsersPage = async () => {
      const listUsers = httpsCallable<ListUsersRequest, ListUsersResponse>(functions, 'listUsers');
      try {
        setUsersLoading(true);
        const response = await listUsers({ pageToken });
        setUsersList(response.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('Error getting users list:', error);
        showError(error.message);
      } finally {
        setUsersLoading(false);
      }
    };
    getUsersPage();
  }, [functions, pageToken]);

  if (usersLoading) {
    return (
      <Loading />
    );
  }

  return (
    <>
      <h2>Users</h2>
      <div className="w-full overflow-auto">
        <table>
          <tbody className="align-top">
            { usersList.users.map((user) => (
              <tr key={user.uid} className="border-y py-2">
                <td>
                  <Link to={`/super/user/${encodeURIComponent(user.email)}`}>
                    ✏️
                  </Link>
                </td>
                <td>
                  <img
                    src={user.photoURL || ''}
                    alt="user avatar"
                    className="rounded-full w-5 h-5"
                  />
                </td>
                <td>
                  { user.displayName }
                </td>
                <td className="pl-3">
                  { user.email }
                </td>
                <td className="pl-3">
                  { user.claims.invited ? null : (
                    <b>Waitlisted</b>
                  ) }
                  { user.claims.super && (
                    <b>Superadmin</b>
                  ) }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        disabled={!usersList.pageToken}
        onClick={() => { setSearchParams({ page: pageToken || '' }); }}
      >
        Next page
      </button>
    </>
  );
}
