// Component that allows live editing of a single field in a Firestore
// document. Writes are throttled.
import { throttle } from 'lodash';
import React, { useMemo, useEffect, useState } from 'react';
import { updateDoc, DocumentReference, doc } from 'firebase/firestore';
import { useFirestoreDoc } from 'reactfire';

type Props ={
  documentRef: DocumentReference;
  valuePath: string;
};

export function FirestoreInput({ documentRef, valuePath }: Props) {
  const [inputValue, setInputValue] = useState('');

  // documentRef is probably a new object every time this component is
  // rendered, but we need it to be stable for throttledUpdate below.
  const stableRef = useMemo(
    () => doc(documentRef.firestore, documentRef.path),
    [documentRef.firestore, documentRef.path],
  );
  const { data } = useFirestoreDoc(stableRef);
  const dataValue = data?.get(valuePath) || '';

  const throttledUpdate = useMemo(
    () => throttle((newValue: string) => {
      updateDoc(stableRef, { [valuePath]: newValue });
    }, 1500),
    [stableRef, valuePath],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    throttledUpdate(newValue);
  };

  useEffect(() => {
    setInputValue(dataValue);
  }, [dataValue]);

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      // Save immediately when the input loses focus (includes page navigation).
      onBlur={throttledUpdate.flush}
    />
  );
}

export default FirestoreInput;
