import React from 'react';

import { GroupMember, InvitedGroupMember } from './common/firestoreTypes';

type UserInfoProps = {
  user: GroupMember | InvitedGroupMember,
};

export default function UserInfo({
  user,
}: UserInfoProps) {
  if ('invitationId' in user) {
    return (
      <span className="inline-flex flex-row items-center">
        <i>
          { user.displayName }
          &nbsp;
          (pending)
        </i>
      </span>
    );
  }
  return (
    <span className="inline-flex flex-row items-center">
      <img
        src={user.photoURL || ''}
        alt="user avatar"
        className="rounded-full w-5 h-5"
      />
      &nbsp;
      { user.displayName }
    </span>
  );
}
