import React from 'react';
import { Link } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { useAuth, useUser } from 'reactfire';
import { Menu } from '@headlessui/react';
import { usePostHog } from 'posthog-js/react';

import { useSuperadmin } from './user';

const mainMenuLinks = [
  { title: 'Help', path: '/' },
  { title: 'Your friends', path: '/groups' },
  { title: 'Your meetings', path: '/meetings' },
  { title: 'Invite a friend', path: '/newGroup' },
  { title: 'Settings', path: '/settings' },
  { title: 'Calendar integration', path: '/gcal' },
];

export default function Header() {
  const auth = useAuth();
  const { data: user } = useUser();
  const isSuperadmin = useSuperadmin();
  const posthog = usePostHog();

  if (!user) {
    return null;
  }

  const menuLinks = isSuperadmin
    ? [...mainMenuLinks, { title: 'Superadmin', path: '/super ' }]
    : mainMenuLinks;

  return (
    <div className="flex flex-row items-center px-1 py-3 border-b border-black whitespace-nowrap justify-between">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="inline-flex text-black bg-transparent hover:bg-transparent p-0">
          ☰
        </Menu.Button>
        <Menu.Items className="absolute bg-white border border-black p-2 whitespace-nowrap">
          { menuLinks.map(({ title, path }) => ((
            <Menu.Item key={title}>
              <Link to={path} className="group flex">{ title }</Link>
            </Menu.Item>
          )))}
          <Menu.Item>
            <button
              onClick={() => {
                signOut(auth);
                posthog.reset();
              }}
            >
              Sign out
            </button>
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <b>
        <Link to="/">
          Friend Scheduler
        </Link>
      </b>
      <span className="flex flex-row">
        <img
          src={user.photoURL || ''}
          alt="user avatar"
          className="rounded-full w-6 h-6"
        />
        { user.displayName }
      </span>
    </div>
  );
}
