import groupBy from 'lodash/groupBy';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  collection,
  Query,
  query,
  QueryDocumentSnapshot,
  orderBy,
  where,
} from 'firebase/firestore';
import {
  useFirestore,
  useFirestoreCollection,
} from 'reactfire';

import { Meeting } from './common/firestoreTypes';
import { msPerWeek } from './common/timeUtil';
import Loading from './Loading';

type MeetingListProps = {
  userId?: string,
  groupId?: string,
};

export default function MeetingList({
  userId,
  groupId,
}: MeetingListProps) {
  const firestore = useFirestore();
  const [recent] = useState(new Date(Date.now() - 24 * msPerWeek));
  const { data: meetings } = useFirestoreCollection<Meeting>(
    query(
      collection(firestore, 'meetings'),
      where('start', '>', recent),
      userId ? where('membersUids', 'array-contains', userId) : where('groupId', '==', groupId),
      orderBy('start', 'desc'),
    ) as Query<Meeting>,
  );

  if (!meetings) {
    return <Loading />;
  }

  const { upcoming, past } = groupBy(meetings.docs, (meeting) => (
    meeting.data()?.start.toDate() >= new Date() ? 'upcoming' : 'past'
  ));
  const noUpcoming = !upcoming?.some((meeting) => !meeting.data()?.canceled);

  const renderMeeting = (groupSnap: QueryDocumentSnapshot<Meeting>) => (
    <div key={groupSnap.id}>
      <Link to={`/meeting/${groupSnap.id}`}>
        { groupSnap.data()?.start.toDate().toLocaleString() }
        &emsp;
        { groupSnap.data()?.groupName }
        { groupSnap.data()?.canceled && (
          <>
            &emsp;
            <b>Canceled</b>
          </>
        )}
      </Link>
    </div>
  );

  return (
    <div>
      <h2>Upcoming meetings</h2>
      { noUpcoming && (
        <div>
          Friend Scheduler will schedule a time for you when it gets a chance!
        </div>
      )}
      { upcoming?.map(renderMeeting) }
      <br />
      <h2>Recent meetings</h2>
      { !past?.length && (
        <div>
          No recent meetings
        </div>
      )}
      { past?.map(renderMeeting) }
    </div>
  );
}
