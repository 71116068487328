import React from 'react';
import { Query, collection, limit, orderBy, query } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { useFirestore, useFirestoreCollection } from 'reactfire';

import { SchedulingRun } from '../common/firestoreTypes';
import Loading from '../Loading';

export default function SchedulingRuns() {
  const firestore = useFirestore();
  const { data: runs } = useFirestoreCollection<SchedulingRun>(
    query(
      collection(firestore, 'schedulingRuns'),
      orderBy('start', 'desc'),
      limit(50),
    ) as Query<SchedulingRun>,
  );

  if (!runs) {
    return <Loading />;
  }

  return (
    <div>
      { runs.docs.map((runSnap) => {
        const run = runSnap.data();
        return (
          <div key={runSnap.id}>
            { run.dryRun && (
              <span style={{ color: '#0a0', fontWeight: 'bold' }}>
                Dry Run
                &nbsp;
              </span>
            )}
            <Link to={`/super/runs/${runSnap.id}`}>
              { run.start.toDate().toLocaleString() }
              &nbsp;to&nbsp;
              { run.end.toDate().toLocaleString() }
            </Link>
            { run.exception && (
              <span style={{ color: '#a00' }}>
                &nbsp;
                { run.exception }
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
}
