import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';

import Loading from '../Loading';
import {
  RunSchedulingRequest,
  RunSchedulingResponse,
} from '../common/functionsTypes';
import { useSuperadmin } from '../user';
import AllUsers from './AllUsers';
import EditUser from './EditUser';
import SchedulingRuns from './SchedulingRuns';
import SchedulingRunDetail from './SchedulingRunDetail';
import { showConfirmation, showError } from '../modal';

function SuperadminHomePage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const functions = useFunctions();

  const runScheduling = async (dryRun: boolean) => {
    const runSchedulingManual = httpsCallable<RunSchedulingRequest, RunSchedulingResponse>(
      functions,
      'runSchedulingManual',
    );
    try {
      setLoading(true);
      const response = await runSchedulingManual({ dryRun });
      const { runId } = response.data;
      navigate(`/super/runs/${runId}`);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error running scheduling:', error);
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendRsvpReminders = async () => {
    const sendRsvpRemindersManual = httpsCallable(functions, 'sendRsvpRemindersManual');
    try {
      setLoading(true);
      await sendRsvpRemindersManual();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error sending RSVP reminders:', error);
      showError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const runSchedulingForReal = async () => {
    await showConfirmation('Run the scheduling algorithm for real?');
    await runScheduling(false);
  };

  const runSchedulingDryRun = async () => {
    await runScheduling(true);
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <div>
      <button onClick={runSchedulingForReal}>
        Run Scheduling
      </button>
      <button onClick={runSchedulingDryRun}>
        Dry Run
      </button>
      <br />
      <br />
      <button onClick={sendRsvpReminders}>
        Send RSVP Reminders
      </button>
      <br />
      <br />
      <Link to="/super/runs">Scheduling runs</Link>
      <br />
      <Link to="/super/users">All users</Link>
    </div>
  );
}

export default function SuperadminRoutes() {
  const isSuperadmin = useSuperadmin();

  if (!isSuperadmin) {
    return null;
  }

  return (
    <Routes>
      <Route index element={<SuperadminHomePage />} />
      <Route path="users" element={<AllUsers />} />
      <Route path="user/:email" element={<EditUser />} />
      <Route path="runs" element={<SchedulingRuns />} />
      <Route path="runs/:runId" element={<SchedulingRunDetail />} />
    </Routes>
  );
}
