import React from 'react';

import { WeeklyTimeRange } from './common/firestoreTypes';
import { shortDayString } from './common/timeUtil';
import { showModal } from './modal';
import WeeklyTimeRangeEditor from './WeeklyTimeRangeEditor';

function timeToString(hour: number, minute: number): string {
  if (minute === 0) {
    if (hour === 0) {
      return 'midnight';
    }
    if (hour === 12) {
      return 'noon';
    }
  }
  const ampm = hour < 12 ? 'am' : 'pm';
  const hourString = (hour === 0 || hour === 12) ? '12' : String(hour % 12);
  const minuteString = minute === 0 ? '' : `:${String(minute).padStart(2, '0')}`;
  return hourString + minuteString + ampm;
}

function timeRangeToString(range: WeeklyTimeRange): string {
  const dayString = range.start.day === range.end.day ? '' : ` ${shortDayString[range.end.day]}`;
  const startString = timeToString(range.start.hour, range.start.minute);
  const endString = timeToString(range.end.hour, range.end.minute);
  return `${startString}-${endString}${dayString}`;
}

function editTimeRange(range: WeeklyTimeRange, title: string): Promise<WeeklyTimeRange> {
  return showModal(
    WeeklyTimeRangeEditor,
    range,
    { title },
  );
}

type DayTimeRangesEditorProps = {
  day: number,
  ranges: { id: string, range: WeeklyTimeRange }[],
  onAdd: (newRange: WeeklyTimeRange) => void,
  onDelete: (id: string) => void,
  onChange: (id: string, newRange: WeeklyTimeRange) => void,
};

export default function DayTimeRangesEditor({
  day,
  ranges,
  onAdd,
  onDelete,
  onChange,
}: DayTimeRangesEditorProps) {
  return (
    <>
      { ranges.map(({ range, id }, index) => (
        <React.Fragment key={id}>
          { index > 0 && '; ' }
          { timeRangeToString(range) }
          <button
            className="plain-button"
            title="Edit"
            onClick={async () => {
              const newRange = await editTimeRange(range, 'Editing times');
              onChange(id, newRange);
            }}
          >
            ✏️
          </button>
          <button
            className="plain-button"
            title="Delete"
            onClick={() => onDelete(id)}
          >
            🗑️
          </button>
        </React.Fragment>
      )) }
      <button
        className="plain-button"
        title={`Add times on ${shortDayString[day]}`}
        onClick={async () => {
          const newRange = await editTimeRange(
            {
              start: { day, hour: 19, minute: 0 },
              end: { day, hour: 21, minute: 0 },
            },
            'Adding times',
          );
          onAdd(newRange);
        }}
      >
        ➕
      </button>
    </>
  );
}
