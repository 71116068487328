// Component that renders nothing, but while the component is rendered, the
// loading animation built into index.html is made visible (using the global
// functions defined there). That is, when the number of instances of this
// component that are currently rendered goes from zero to one, it calls
// window.frscLoadingOn(), and when it goes from one to zero, it calls
// window.fsrcLoadingOff().

import { useEffect } from 'react';

declare global {
  // Make TypeScript accept the extra property on window
  interface Window {
    frscLoadingOn: () => void,
    frscLoadingOff: () => void,
  }
}

// Loading animation is shown when index.html first loads, so turn it off when
// the React app is loaded.
window.frscLoadingOff();

// Keeps track of how many Loading components are currently rendered
let loadingStack = 0;

export default function Loading() {
  useEffect(() => {
    loadingStack++;
    if (loadingStack === 1) {
      window.frscLoadingOn();
    }
    return () => {
      loadingStack--;
      if (loadingStack === 0) {
        window.frscLoadingOff();
      }
    };
  }, []);

  return null;
}
