import {
  collection,
  orderBy,
  Query,
  query,
  where,
} from 'firebase/firestore';
import { useFirestore, useFirestoreCollection } from 'reactfire';

import { Group } from './common/firestoreTypes';

// Currently just a convenient shortcut for getting the user's groups from
// Firestore. Might become a more complete data model.
export function useGroups(uid: string) {
  const firestore = useFirestore();

  const { data } = useFirestoreCollection<Group>(
    query(
      collection(firestore, '/groups'),
      where('membersUids', 'array-contains', uid),
      orderBy('createdAt', 'desc'),
    ) as Query<Group>,
  );
  return data;
}
