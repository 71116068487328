import { map } from 'lodash';
import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';

import { useUserState } from './user';
import {
  shortDayString,
  weeklyTimeCompare,
} from './common/timeUtil';
import Loading from './Loading';
import {
  WeeklyTimeRange,
  defaultMaxMeetingsPerWeek,
} from './common/firestoreTypes';
import DayTimeRangesEditor from './DayTimeRangesEditor';
import NumberSelect from './NumberSelect';

// Temp hack: https://github.com/microsoft/TypeScript/issues/49231#issuecomment-1137251612
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  type Key = 'calendar' | 'collation' | 'currency' | 'numberingSystem' | 'timeZone' | 'unit';
  function supportedValuesOf(input: Key): string[];
}

const minMeetings = 1;
const maxMeetings = 15;

export default function Settings() {
  const model = useUserState();
  const { loading, userState } = model;
  const { timeZone } = userState;

  if (loading) {
    return <Loading />;
  }

  const timeRanges = map(
    userState.timeRanges || {},
    (range, id) => ({ range, id }),
  );
  timeRanges.sort((a, b) => weeklyTimeCompare(a.range.start, b.range.start));
  const rangesByDay: Array<Array<{ range: WeeklyTimeRange, id: string }>>
    = [[], [], [], [], [], [], []];
  for (const range of timeRanges) {
    rangesByDay[range.range.start.day].push(range);
  }
  const maxMeetingsPerWeek = userState.maxMeetingsPerWeek || defaultMaxMeetingsPerWeek;

  const onTimeZoneChange = (event: ChangeEvent<HTMLSelectElement>) => {
    model.timeZoneSet(event.target.value);
  };

  return (
    <div>
      <h2>Settings</h2>
      <br />
      <p>
        Max number of meetings per week:&emsp;
        <NumberSelect
          value={maxMeetingsPerWeek}
          min={minMeetings}
          max={maxMeetings}
          setValue={model.maxMeetingsPerWeekSet}
        />
      </p>
      <p>
        Time zone:&emsp;
        <select value={timeZone} onChange={onTimeZoneChange}>
          {
            Intl.supportedValuesOf('timeZone').map((tz: string) => (
              <option key={tz} value={tz}>
                { tz }
              </option>
            ))
          }
        </select>
      </p>
      <br />
      <hr />
      <br />
      <h2>Schedule blocks</h2>
      <br />
      <p>
        These are blocks of times when you are willing to have meetings with your
        friends scheduled for you. If you have integrated your calendar system, meetings
        will also avoid conflicts with your scheduled events.
      </p>
      <ul>
        { rangesByDay.map((ranges, day) => (
          <li key={day}>
            <b>
              { shortDayString[day] }
              &ensp;
            </b>
            <DayTimeRangesEditor
              day={day}
              ranges={ranges}
              onAdd={(newRange) => model.timeRangeAdd(newRange)}
              onDelete={(id) => model.timeRangeDelete(id)}
              onChange={(id, newRange) => model.timeRangeUpdate(id, newRange)}
            />
          </li>
        )) }
      </ul>
      <br />
      <hr />
      <br />
      <h2>Calendar integration</h2>
      <br />
      <p>
        Go <Link to="/gcal">here</Link> to integrate with Google Calendar.
      </p>
    </div>
  );
}
