
export type Config = {
  firebase: {
    apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string,
  },
};

let config: Config = {
  firebase: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
};

// Using chained if...then here lets the bundler remove the configuration(s)
// that aren't being used.
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  config = {
    firebase: {
      apiKey: 'AIzaSyDxS6qqRNXH2F5v0TOXTnQaB8akmf0VM04',
      authDomain: 'frienduler.com',
      projectId: 'friend-scheduler',
      storageBucket: 'friend-scheduler.appspot.com',
      messagingSenderId: '791486733554',
      appId: '1:791486733554:web:5ab454d6bfb2241633446b',
      measurementId: 'G-KE3ZMTJKJX',
    },
  };
} else if (process.env.REACT_APP_ENVIRONMENT === 'debug') {
  config = {
    firebase: {
      // Mostly not really used because we use the emulator for local debugging
      apiKey: 'AIzaSyDoRqRTQHPTE1cg0uUSMBGnJZirvMQMIM4',
      authDomain: 'friend-scheduler-debug.firebaseapp.com',
      projectId: 'friend-scheduler-debug',
      storageBucket: 'friend-scheduler-debug.appspot.com',
      messagingSenderId: '936634726286',
      appId: '1:936634726286:web:1868038a8d3ebafbf956df',
      measurementId: '',
    },
  };
}

export default config;
