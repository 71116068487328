import React from 'react';
import { Link } from 'react-router-dom';
import { User } from 'firebase/auth';

import UserGroupList from './UserGroupList';
import { useUserState } from './user';
import Loading from './Loading';

type HelpPageProps = {
  user: User,
};

export default function HelpPage({
  user,
}: HelpPageProps) {
  const model = useUserState();
  const { loading, userState } = model;

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <>
      <h1>Welcome!</h1>
      <br />
      <p>
        You now have the following connections:
      </p>
      <div>
        <UserGroupList uid={user.uid} showHeader={false} />
      </div>
      <br />
      <h2>Setup checklist</h2>
      <p>
        <input type="checkbox" disabled checked={Boolean(userState.timeRanges)} />
        &ensp;
        Set up some regular time blocks when you would like to meet with your friends.
        You can do this on the <Link to="/settings">Settings</Link> page.
      </p>
      <p>
        <input type="checkbox" disabled checked={userState.integrations?.googleCalendar?.integrated} />
        &ensp;
        Give Friend Scheduler <Link to="/gcal">access to your Google Calendar</Link>. (Sorry,
        it doen&apos;t yet know how to integrate with other calendar systems.)
      </p>
      <p>
        If you only do one or the other of the above, Friend Scheduler can still schedule events for
        you, but it might pick times that aren&apos;t convenient for you.
      </p>
      <p>
        You can also <Link to="/newGroup">invite other friends</Link> to
        connect on Friend Scheduler.
      </p>
      <p>
        Any of these pages can also be found using the menu at the upper left.
      </p>
      <br />
      <p>
        <b>
          Trouble? Feedback? Write to <a href="mailto:help@frienduler.com" target="_blank" rel="noreferrer">
            help@frienduler.com
          </a>
        </b>
      </p>
      <a href="/about.html" target="_blank" rel="noreferrer">About</a>
      <br />
      <a href="/privacy.html" target="_blank">Privacy policy</a>
      <br />
      <a href="/tos.html" target="_blank">Terms of Service</a>
    </>
  );
}
