import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';
import { usePostHog } from 'posthog-js/react';

import SignInPage from './SignInPage';
import Header from './Header';
import UserGroupList from './UserGroupList';
import NewGroup from './NewGroup';
import Settings from './Settings';
import GroupSettings from './GroupSettings';
import GoogleCalendarIntegration from './GoogleCalendarIntegration';
import AcceptInvitation from './AcceptInvitation';
import {
  MinimalUserContext,
  minimalUserFromUserInfo,
  SuperadminContext,
} from './user';
import HelpPage from './HelpPage';
import SuperadminRoutes from './superadmin/SuperadminRoutes';
import Loading from './Loading';
import MeetingList from './MeetingList';
import MeetingPage from './MeetingPage';

export default function AppRoutes() {
  const location = useLocation();
  const { status, data: signInCheckResult } = useSigninCheck({
    validateCustomClaims: (claims) => ({
      hasRequiredClaims: 'invited' in claims,
      // It seems the only way to return any information about custom claims
      // other than the boolean hasRequiredClaims is to return it as an error.
      // Error properties can have any name, but the value has to be an array
      // (of any).
      errors: {
        isSuperadmin: ['super' in claims],
      },
    }),
  });
  const isSuperadmin = signInCheckResult?.signedIn
    ? (signInCheckResult?.errors?.isSuperadmin?.[0] || false)
    : false;
  const invited = signInCheckResult?.hasRequiredClaims || false;
  const user = signInCheckResult?.user;
  const email = user?.email;
  const displayName = user?.displayName;
  const signedIn = signInCheckResult?.signedIn || false;

  const posthog = usePostHog();
  useEffect(() => {
    if (user) {
      posthog.identify(
        user.uid,
        {
          email,
          displayName,
          invited,
          isSuperadmin,
        },
      );
    }
  }, [displayName, email, invited, isSuperadmin, posthog, user]);

  if (status === 'loading') {
    return <Loading />;
  }

  if (!signedIn) {
    return (<SignInPage />);
  }
  if (!user) {
    return null;
  }
  const minimalUser = minimalUserFromUserInfo(user);

  const routes = () => {
    // Users' tokens need to have the custom "invited" claim to prove they've
    // been invited to the app, unless of course they're currently trying to
    // accept an invitation.
    if (!(signInCheckResult.hasRequiredClaims || location.pathname.startsWith('/accept/'))) {
      return (
        <div>
          <h2>Wait list</h2>
          You are currently on the wait list for Friend Scheduler.
        </div>
      );
    }
    return (
      <Routes>
        <Route path="/" element={<HelpPage user={user} />} />
        <Route path="/groups" element={<UserGroupList uid={user.uid} showHeader />} />
        <Route path="/meetings" element={<MeetingList userId={user.uid} />} />
        <Route path="/meeting/:meetingId" element={<MeetingPage userId={user.uid} />} />
        <Route path="/newGroup" element={<NewGroup user={user} />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/group/:groupId" element={<GroupSettings user={user} />} />
        <Route path="/gcal/*" element={<GoogleCalendarIntegration user={user} />} />
        <Route path="/accept/:invitationId/:secret" element={<AcceptInvitation user={user} />} />
        <Route path="/super/*" element={<SuperadminRoutes />} />
      </Routes>
    );
  };

  return (
    <MinimalUserContext.Provider value={minimalUser}>
      <SuperadminContext.Provider value={isSuperadmin}>
        <div className="flex flex-row justify-center w-screen">
          <div className="relative inline-block w-screen max-w-screen-lg px-1">
            <Header />
            <div className="py-3">
              { routes() }
            </div>
          </div>
        </div>
      </SuperadminContext.Provider>
    </MinimalUserContext.Provider>
  );
}
