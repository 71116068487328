import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getIdToken, User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';

import { showError } from './modal';

type AcceptInvitationProps = {
  user: User,
}

export default function AcceptInvitation({
  user,
}: AcceptInvitationProps) {
  const { invitationId, secret } = useParams();
  const functions = useFunctions();
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const accept = async () => {
      const acceptInvitation = httpsCallable(functions, 'acceptInvitation');
      try {
        setLoading(true);
        await acceptInvitation({ invitationId, secret });
        // Force token refresh so user will have the "invited" claim
        await getIdToken(user, true);
        setDone(true);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('Error accepting invitation:', error);
        showError(error.message);
      } finally {
        setLoading(false);
      }
    };
    accept();
  }, [functions, invitationId, secret, user]);

  if (done) {
    return (
      <div>
        <h3>Invitation accepted</h3>
        <Link to="/">
          Continue
        </Link>
      </div>
    );
  }

  if (loading) {
    return (
      <h3>Accepting Invitation...</h3>
    );
  }

  return (
    <h3>Something&apos;s wrong. Try reloading maybe?</h3>
  );
}
