import React from 'react';

export type NumberSelectProps = {
  value: number,
  setValue: (value: number) => void,
  // The numbers shown are the intersection of the min-to-max inclusive range
  // (if max is specified and > min), values (if specified), and value.
  min?: number, // Defaults to 1; ignored if max is not specified
  max?: number,
  values?: Array<number>, // Additional values to include in the select
};

// A select element for choosing a number from a specified set of numbers.
export default function NumberSelect({
  value,
  setValue,
  min = 1,
  max,
  values,
}: NumberSelectProps) {
  const valuesSet = new Set<number>();
  if (max !== undefined && min <= max) {
    for (let i = min; i <= max; i++) {
      valuesSet.add(i);
    }
  }
  if (values) {
    for (const v of values) {
      valuesSet.add(v);
    }
  }
  valuesSet.add(value);
  const allValues = Array.from(valuesSet);
  allValues.sort((a, b) => a - b);
  return (
    <select
      onChange={(event) => { setValue(Number(event.target.value)); }}
      value={value}
    >
      {allValues.map((v) => (
        <option key={v} value={v}>{v}</option>
      ))}
    </select>
  );
}
