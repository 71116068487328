import { difference } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { User } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import {
  useFunctions,
} from 'reactfire';

import { CalendarData } from './common/firestoreTypes';
import { useUserState } from './user';
import {
  optionalScopes,
  optionalScopesReasons,
  requiredScopes,
  requiredScopesReasons,
} from './common/gcal';
import Loading from './Loading';
import { showError, showMessage } from './modal';
import { GCalIntegrationRedirectRequest, GCalIntegrationRedirectResponse } from './common/functionsTypes';

// For sorting calendars. The primary calendar should be first in the sorted
// list; all others should be alphabetical.
function calSortKey(cal: CalendarData) {
  if (cal.primary) {
    return ''; // sorts before all other strings
  }
  return cal.summary;
}

type GCalIntegrationProps = {
  user: User,
};

function GCalIntegrationStatus({
  user,
}: GCalIntegrationProps) {
  const [loadingFlag, setLoadingFlag] = useState(false);
  const functions = useFunctions();
  const model = useUserState();
  const { loading: userStateLoading, userState } = model;
  const loading = loadingFlag || userStateLoading;

  if (loading) {
    return <Loading />;
  }

  const connect = async () => {
    const getOauthUrl = httpsCallable<void, { authorizeUrl: string }>(functions, 'getGcalOauthUrl');
    try {
      setLoadingFlag(true);
      const result = await getOauthUrl();
      window.location.href = result.data.authorizeUrl;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setLoadingFlag(false);
      console.error('Error connecting to Google Calendar:', err);
      showError(err.message);
    }
  };

  const disconnect = async () => {
    const disconnectGcal = httpsCallable<void, void>(functions, 'disconnectGcal');
    try {
      setLoadingFlag(true);
      await disconnectGcal();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error('Error disconnecting from Google Calendar:', err);
      showError(err.message);
    } finally {
      setLoadingFlag(false);
    }
  };

  const refreshCalendars = async () => {
    const refreshGcalCalendars = httpsCallable<void, void>(functions, 'refreshGcalCalendars');
    try {
      setLoadingFlag(true);
      await refreshGcalCalendars();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error('Error refreshing calendar list:', err);
      showError(err.message);
    } finally {
      setLoadingFlag(false);
    }
  };

  const currentScopes = userState?.integrations?.googleCalendar?.scopes || [];
  const missingRequiredScopes = difference(requiredScopes, currentScopes);
  const missingAnyRequiredScopes = missingRequiredScopes.length !== 0;
  const missingOptionalScopes = difference(optionalScopes, currentScopes);
  const missingAnyOptionalScopes = missingOptionalScopes.length !== 0;
  const calendars = Object.values(userState?.integrations?.googleCalendar?.calendars || []);
  calendars.sort((calA, calB) => calSortKey(calA).localeCompare(calSortKey(calB)));

  const integrationErrorState = () => {
    const error = userState.integrations?.googleCalendar?.error;
    if (!error) {
      return null;
    }
    return (
      <div style={{ color: '#d00', fontWeight: 'bold' }}>
        <br />
        ⚠ { error }
        <br />
        <br />
      </div>
    );
  };

  const integrationState = () => {
    if (userState?.integrations?.googleCalendar?.integrated) {
      return (
        <>
          <div>
            <h3>✅ Connected</h3>
          </div>
          <div>
            { missingAnyRequiredScopes && (
              <>
                <b>BUT</b>
                <p>You still need to grant some Google Calendar permissions. *</p>
              </>
            )}
            { missingAnyOptionalScopes && !missingAnyRequiredScopes && (
              <>
                <b>BUT</b>
                <p>
                  You may still grant some <b>optional</b> Google Calendar
                  permissions for additional functionality. *
                </p>
              </>
            )}
            { (missingAnyRequiredScopes || missingAnyOptionalScopes) && (
              <>
                <button onClick={connect}>
                  Authorize
                </button>
              </>
            )}
            <br />
            <button onClick={disconnect}>
              Disconnect
            </button>
          </div>
        </>
      );
    }
    return (
      <div>
        <button onClick={connect}>
          Connect to Google Calendar
        </button>
        &nbsp;*
        <br />
      </div>
    );
  };

  const allScopes = () => (
    <>
      { missingAnyRequiredScopes && (
        <div>
          <br />
          * When Google prompts you to grant permissions, you will need to check the checkbox
          next to each of the following:
          <ul>
            { missingRequiredScopes.map(((scope) => (
              <li key={scope}>
                <b>{ requiredScopesReasons[scope].name }:</b>
                &ensp;{ requiredScopesReasons[scope].reason }
              </li>
            )))}
          </ul>
          Without all of these permissions, Friend Scheduler&apos;s Calendar integration
          will not work.
        </div>
      )}
      { missingAnyOptionalScopes && (
        <div>
          <br />
          * These additional permissions are <b>optional</b>:
          <ul>
            { missingOptionalScopes.map(((scope) => (
              <li key={scope}>
                <b>{ optionalScopesReasons[scope].name }:</b>
                &ensp;{ optionalScopesReasons[scope].reason }
              </li>
            )))}
          </ul>
        </div>
      )}
    </>
  );

  const onCheckboxChanged = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
    model.googleCalendarSetUse(id, event.target.checked);
  };

  return (
    <div>
      <div>
        <h2>Google Calandar integration</h2>
        { integrationErrorState() }
        { integrationState() }
        { allScopes() }
      </div>
      { !missingAnyRequiredScopes && (
        <>
          <br />
          <hr />
          <br />
          <p>
            Select the calendars that Friend Scheduler should use to determine when
            you&apos;re free.
          </p>
          <button onClick={refreshCalendars}>
            Refresh list
          </button>
          <br />
          <br />
          <table>
            <tbody className="align-top">
              { calendars.map((cal) => (
                <tr key={cal.id} className="border-y py-2">
                  <td style={{ color: cal.backgroundColor }}>⬤</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={cal.use}
                      onChange={onCheckboxChanged(cal.id)}
                    />
                  </td>
                  <td
                    style={{
                      fontWeight: cal.primary ? 'bold' : 'normal',
                      maxWidth: '50vw',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    { cal.summary }
                  </td>
                  <td className="pl-3">
                    { cal.primary ? (
                      <b>Your primary calendar</b>
                    ) : (
                      cal.description
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}

const alreadyFetched = new Set<string>();

function GCalIntegrationRedirect({
  user,
}: GCalIntegrationProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const functions = useFunctions();
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const state = params.get('state');
  const error = params.get('error') || '';

  useEffect(() => {
    if (error === 'access_denied') {
      // This happens when the user clicks "Cancel" on the Google OAuth page.
      navigate('/gcal', { replace: true });
      return;
    }
    if (!code || !state) {
      return;
    }
    if (alreadyFetched.has(state)) {
      return;
    }
    const finish = async () => {
      console.log('sending');
      // Prevent calling the API twice with the same code. This is mainly a
      // problem with React's Strict Mode rendering everything twice, but good
      // to avoid in general.
      alreadyFetched.add(state);
      const sendResults = httpsCallable<
        GCalIntegrationRedirectRequest,
        GCalIntegrationRedirectResponse
      >(functions, 'handleGcalOauthRedirect');
      try {
        const response = await sendResults({ code, state });
        if (response.data.createdCalendarName) {
          showMessage((
            <div>
              Friend Scheduler has created a calendar
              named &ldquo;{response.data.createdCalendarName}&rdquo;. New
              events will automatically be added to this calendar. You may
              want to check in Google Calendar to make sure it&apos;s
              visible. <b>Existing events have NOT been added to it.</b>
            </div>
          ));
        }
        navigate('/gcal', { replace: true });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error('Error connecting to Google Calendar:', err);
        await showError(err.message);
        navigate('/gcal', { replace: true });
      }
    };
    finish();
  }, [code, error, functions, navigate, state]);

  if (!code || !state) {
    return (
      <p>
        Missing URL parameters
        <br />
        { error }
      </p>
    );
  }

  return <Loading />;
}

export default function GoogleCalendarIntegration({
  user,
}: GCalIntegrationProps) {
  return (
    <Routes>
      <Route index element={<GCalIntegrationStatus user={user} />} />
      <Route path="redirect" element={<GCalIntegrationRedirect user={user} />} />
    </Routes>
  );
}
