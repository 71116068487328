import { createContext, useCallback, useContext, useEffect } from 'react';
import { UserInfo } from 'firebase/auth';
import { useFirestore, useFirestoreDoc } from 'reactfire';
import {
  doc,
  DocumentReference,
  deleteField,
  setDoc,
  FieldValue,
} from 'firebase/firestore';
import md5 from 'md5';
import cryptoRandomString from 'crypto-random-string';

import { MinimalUser, UserState, WeeklyTimeRange } from './common/firestoreTypes';
import { showError } from './modal';

function gravatar(email: string) {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=retro`;
}

export function minimalUserFromUserInfo(user: UserInfo): MinimalUser {
  return {
    uid: user.uid,
    displayName: user.displayName
      || (user.email || '').split('@')[0] || 'unknown name',
    photoURL: user.photoURL || gravatar(user.email || ''),
  };
}

export const MinimalUserContext = createContext<MinimalUser>({
  uid: '',
  displayName: 'no user',
  photoURL: '',
});

export function useMinimalUser() {
  return useContext(MinimalUserContext);
}

export const SuperadminContext = createContext<boolean>(false);

export function useSuperadmin() {
  return useContext(SuperadminContext);
}

export type UserStateModel = {
  loading: boolean,
  userState: UserState,
  user: MinimalUser,

  googleCalendarSetUse: (id: string, use: boolean) => void,
  maxMeetingsPerWeekSet: (max: number) => void,
  timeZoneSet: (timeZone: string) => void,
  timeRangeAdd: (timeRange: WeeklyTimeRange) => void,
  timeRangeDelete: (id: string) => void,
  timeRangeUpdate: (id: string, timeRange: WeeklyTimeRange) => void,
};

export function useUserState(): UserStateModel {
  const user = useMinimalUser();
  const firestore = useFirestore();
  const userStateRef = doc(firestore, `userStates/${user.uid}`) as DocumentReference<UserState>;
  const { data, error, status } = useFirestoreDoc(userStateRef);
  const userState: UserState = data?.data() || {};
  const loading = status !== 'success';

  useEffect(() => {
    if (status === 'error') {
      showError(`Firestore error: ${error}`);
    }
  }, [error, status]);

  const timeZoneSet = useCallback((timeZone: string) => {
    setDoc(
      userStateRef,
      { timeZone },
      { merge: true },
    );
  }, [userStateRef]);

  useEffect(() => {
    window.setTimeout(
      () => {
        if (!loading && !userState.timeZone) {
          // eslint-disable-next-line new-cap
          timeZoneSet(Intl.DateTimeFormat().resolvedOptions().timeZone);
        }
      },
      1,
    );
  }, [loading, timeZoneSet, userState.timeZone]);

  const updateTimeRanges = (
    updates: { [id: string]: (WeeklyTimeRange | FieldValue) },
  ) => {
    setDoc(
      userStateRef,
      { timeRanges: updates },
      { merge: true },
    );
  };

  return {
    loading,
    userState,
    user,

    googleCalendarSetUse: (id: string, use: boolean) => {
      setDoc(
        userStateRef,
        { integrations: { googleCalendar: { calendars: {
          [id]: { use },
        } } } },
        { merge: true },
      );
    },

    maxMeetingsPerWeekSet: (maxMeetingsPerWeek: number) => {
      setDoc(
        userStateRef,
        { maxMeetingsPerWeek },
        { merge: true },
      );
    },

    timeZoneSet,

    timeRangeAdd: (timeRange: WeeklyTimeRange) => {
      const id = cryptoRandomString({ length: 18, type: 'url-safe' });
      updateTimeRanges({
        [id]: timeRange,
      });
    },

    timeRangeDelete: (id: string) => {
      updateTimeRanges({
        [id]: deleteField(),
      });
    },

    timeRangeUpdate: (id: string, timeRange: WeeklyTimeRange) => {
      updateTimeRanges({
        [id]: timeRange,
      });
    },
  };
}
