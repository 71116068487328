import { FieldValue, Timestamp } from '../fbTypes';

export type MinimalUser = {
  uid: string,
  displayName: string,
  photoURL: string,
}

export type GroupMember = MinimalUser;

export type InvitedGroupMember = {
  email: string,
  displayName: string,
  invitationId: string, // id within "invitations" collection
}

// Groups are stored in the top-level collection "groups".
export type Group = {
  status: 'pending' | 'active' | 'inactive',
  name: string,
  membersUids: Array<string>,
  members: Array<GroupMember | InvitedGroupMember>,
  createdBy: MinimalUser,
  createdAt: Timestamp | FieldValue,
  latestMeeting: Timestamp, // Includes cancelled meetings; initialize to epoch
  defaultLocation?: string,
  maxFreqWeeks?: number, // Group won't meet more often than this
  lastCheckedForImpossible?: Timestamp, // so we can avoid checking too often
}

export const defaultMaxFreqWeeks = 6; // default value for Group.maxFreqWeeks

export type MemberResponse = 'yes' | 'skip' | 'reschedule' | 'no response';
export const responseLabels = {
  yes: 'Yes',
  skip: 'No',
  reschedule: 'No',
  'no response': 'No response',
};

export type CalendarEventInfo = {
  id: string,
  url?: string,
};

// Meetings are stored in the top-level collection "meetings".
export type Meeting = {
  groupId: string,
  groupName: string,
  membersUids: Array<string>,
  createdAt: Timestamp,
  start: Timestamp,
  end: Timestamp,
  location?: string,
  gcalId?: string, // deprecated
  calendarEvents?: {
    // Per-user calendar event info. These are present only for calendar events
    // directly created by the app.
    [uid: string]: {
      gcal?: CalendarEventInfo,
    }
  },
  // NOTE: canceled may be missing in pre-2024 meetings. This should be pretty
  // much ok unless querying for meetings by canceled status-- which we do, but
  // only for recent meetings.
  canceled: boolean, // true if Gcal says canceled, OR any members declined
  responses?: Record<string, MemberResponse>,
  lastNotification?: Timestamp, // so we can avoid sending too many reminders
}

// Invitations are stored in the top-level collection "invitations". This is an
// invitation to join the app and connect with the sender, not a calendar invitation.
export type Invitation = {
  secret: string, // random secret string
  group: string, // id within "groups" collection
  inviteeEmail: string,
  inviteeName: string,
  inviterUid: string,
  inviterName: string,
  accepted?: boolean,
}

// Represents a weekly recurring time, like "Tuesdays at 8:30pm". Values are
// within the user's own time zone. It has to be in the user's time zone
// because when DST starts/ends, the time ranges should stay at the "same
// time", i.e., 8:00pm EST, then 8:00pm EDT. If a time is ambiguous because it
// happens twice in a particular week (the clock gets set back when DST ends,
// so Saturday 1:30am happens twice), it refers to the first such time, i.e.
// the daylight time, not the standard time. If it doesn't happen at all
// because it's skipped when DST starts, it's interpreted as one hour later,
// i.e. in the week of 2023-03-12, Sunday 02:30 is interpreted to mean Sunday
// 03:30. I'm not sure if these DST change behaviors are guaranteed.
export type WeeklyTime = {
  day: number, // 0-6, Sunday = 0. Maybe should be 0 | 1 | 2...?
  hour: number, // 0-23
  minute: number, // 0-59
};

// A weekly block in which the user is willing to have meetings scheduled,
// assuming their calendar also shows them to be free. The end should always be
// later than the beginning, and may not wrap around, i.e. a range may not
// include midnight Saturday/Sunday. Currently we don't even have a way to
// represent a range that ends at midnight Saturday night.
export type WeeklyTimeRange = {
  start: WeeklyTime,
  end: WeeklyTime,
};

export type CalendarData = {
  id: string,
  summary: string,
  description: string,
  primary: boolean,
  backgroundColor: string,
  use: boolean,
};

// These are stored in the top-level collection "userStates". The document ID
// is the user's UID. Each user can read+write their own document from the
// client side (except they can't write to maxGroups); they can't access other
// users' document.
export type UserState = {
  maxMeetingsPerWeek?: number,
  timeZone?: string, // IANA tz database name
  timeRanges?: { [id: string]: WeeklyTimeRange },
  integrations?: {
    googleCalendar?: {
      integrated: boolean,
      scopes: Array<string>,
      error?: string,
      calendars: { [id: string]: CalendarData },
      appCalendarId?: string, // ID of the calendar created by the app
    },
  },
  // Maximum number of groups/connections this user can have. Actual limit is
  // max(maxGroups || 0, defaultMaxGroups).
  maxGroups?: number,
};

export const defaultMaxMeetingsPerWeek = 3;
export const defaultMaxGroups = 0;

// These are stored in the top-level collection "userSecrets". The document ID
// is the user's UID. This data is never readable from the client side.
export type UserSecrets = {
  integrations?: {
    googleCalendar?: {
      oauthState?: string,
      refreshToken?: string,
    },
  },
};

export type SchedulingRun = {
  start: Timestamp,
  end: Timestamp,
  log: string,
  dryRun: boolean,
  exception: string | null,
};
