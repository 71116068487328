// Really easy modals, where all you have to do is call a function, and it
// shows a modal until the user dismisses it.

import React, { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';

type ControlProps<ValueType, ExtraPropsType> = {
  value: ValueType,
  onChange: (newValue: ValueType) => void,
} & ExtraPropsType;

type ControlComponent<ValueType, ExtraPropsType> =
  React.ComponentType<ControlProps<ValueType, ExtraPropsType>>;

export async function showModal<ValueType, ExtraPropsType extends Record<string, never>>(
  Component: ControlComponent<ValueType, ExtraPropsType>,
  initialValue: ValueType,
): Promise<ValueType>;

export async function showModal<ValueType, ExtraPropsType>(
  Component: ControlComponent<ValueType, ExtraPropsType>,
  initialValue: ValueType,
  extraProps: ExtraPropsType,
): Promise<ValueType>;

// Takes a component (something that can be rendered in JSX using <Component/>)
// and an initial value. The component must have at least two props, `value`
// which is a value of type ValueType, and `onChange` which is a function that
// takes one argument of type ValueType. The initial value is of type
// ValueType. The component is rendered inside a modal (with Done and Cancel
// buttons) and allowed to modify a copy of the initial value in the obvious
// way. The return type is a Promise that resolves to the updated value when
// the user clicks Done, which also closes the modal, of course. If the user
// clicks Cancel, the modal is closed and the returned Promise never resolves.
//
// The initial value is copied with structuredClone, so it must be of a type
// that allows that. This includes anything that can be JSONified. (TypeScript
// does not enforce this because structuredClone's argument is of type any.)
//
// Rendering is done in its own temporary root element, not inside the context
// of any React component that calls this. So for example the inner component
// can't access any React contexts.
//
// If the component has any additional props, they should be passed as an
// object in the third argument to showModal.
//
// Example, using an anonymous function component:
//
// const result = await showModal(
//   ({ value, onChange }) => {
//     const changed = (event: ChangeEvent<HTMLInputElement>) => {
//       onChange(Number(event.target.value));
//     };
//     return (
//       <input type="number" value={value} onChange={changed} />
//     );
//   },
//   10,
// );
// console.log(result);
//
export function showModal<ValueType, ExtraPropsType>(
  Component: ControlComponent<ValueType, ExtraPropsType>,
  initialValue: ValueType,
  extraProps?: ExtraPropsType,
): Promise<ValueType> {
  return new Promise<ValueType>((resolve) => {
    const focused = document.activeElement;
    const rootElement = document.createElement('div');
    rootElement.style.zIndex = '999999999';
    rootElement.style.position = 'fixed';
    const root = ReactDOM.createRoot(rootElement);
    document.body.appendChild(rootElement);

    let value: ValueType = structuredClone(initialValue);

    const render = () => {
      root.render((
        <ModalWrapper onDone={onDone} onCancel={unmount} doneLabel="Done">
          {/* @ts-expect-error: no really, this is ok */}
          <Component value={value} onChange={onChange} {...extraProps} />
        </ModalWrapper>
      ));
    };

    const onChange = (newValue: ValueType) => {
      value = newValue;
      render();
    };

    const unmount = () => {
      root.unmount();
      document.body.removeChild(rootElement);
      if (focused && 'focus' in focused && typeof focused.focus === 'function') {
        focused.focus();
      }
    };

    const onDone = () => {
      unmount();
      resolve(value);
    };

    render();
  });
}

// Renders a prompt and a text input. Returns a Promise that resolves to the
// user-input value when they click "Ok". If they click "Cancel", the Promise
// never resolves.
export function showPrompt(prompt: ReactNode, initialValue = ''): Promise<string> {
  return showModal(
    ({ value, onChange }) => {
      const changed = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      };
      return (
        <div>
          <h2>{ prompt }</h2>
          <input
            type="text"
            className="w-96"
            onFocus={(event) => event.target.select()}
            value={value}
            onChange={changed}
          />
        </div>
      );
    },
    initialValue,
  );
}

// Renders a React element inside a modal on the fly, with an "Ok" button to
// close it. Returns a Promise that resolves when the user closes the modal.
//
// The element can be literal JSX or just a string, so you can do something like
// showMessage(<b>Hello</b>) or just showMessage('Hello').
//
// Rendering is done in its own temporary root element, not inside the context
// of any React component that calls this. So for example the inner component
// can't access any React contexts.
export function showMessage(component: ReactNode): Promise<void> {
  return new Promise<void>((resolve) => {
    const focused = document.activeElement;
    const rootElement = document.createElement('div');
    rootElement.style.zIndex = '999999999';
    rootElement.style.position = 'fixed';
    const root = ReactDOM.createRoot(rootElement);
    document.body.appendChild(rootElement);

    const onDone = () => {
      root.unmount();
      document.body.removeChild(rootElement);
      if (focused && 'focus' in focused && typeof focused.focus === 'function') {
        focused.focus();
      }
      resolve();
    };

    root.render((
      <ModalWrapper onDone={onDone} doneLabel="Ok">
        { component }
      </ModalWrapper>
    ));
  });
}

// Convenience function that wraps showMessage and adds an "Error" header at
// the top of the modal.
export function showError(component: ReactNode): Promise<void> {
  return showMessage((
    <div>
      <h2>Error</h2>
      { component }
    </div>
  ));
}

// Renders a React element (usually just a message or other non-interactive
// information) inside a modal on the fly, with "Ok" and "Cancel" buttons.
// Returns a Promise that resolves when the user clicks "Ok". If they click
// "Cancel", the Promise never resolves.
export function showConfirmation(component: ReactNode): Promise<void> {
  return new Promise<void>((resolve) => {
    const focused = document.activeElement;
    const rootElement = document.createElement('div');
    rootElement.style.zIndex = '999999999';
    rootElement.style.position = 'fixed';
    const root = ReactDOM.createRoot(rootElement);
    document.body.appendChild(rootElement);

    const unmount = () => {
      root.unmount();
      document.body.removeChild(rootElement);
      if (focused && 'focus' in focused && typeof focused.focus === 'function') {
        focused.focus();
      }
    };
    const onDone = () => {
      unmount();
      resolve();
    };

    root.render((
      <ModalWrapper onDone={onDone} onCancel={unmount} doneLabel="Ok">
        { component }
      </ModalWrapper>
    ));
  });
}

type ModalWrapperProps = {
  onDone: () => void,
  onCancel?: () => void,
  children: ReactNode,
  doneLabel: string,
};

export function ModalWrapper({
  onDone,
  onCancel,
  children,
  doneLabel,
}: ModalWrapperProps) {
  const modalElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (modalElement.current) {
      // Find the first focusable element inside the modal and focus it. This
      // will be the "Done" button if nothing else.
      const focusable = modalElement.current.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      if (focusable && 'focus' in focusable && typeof focusable.focus === 'function') {
        focusable.focus();
      }
    }
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onKeyDown={(event) => {
        switch (event.key) {
        case 'Enter':
          event.preventDefault();
          onDone();
          break;
        case 'Escape':
          event.preventDefault();
          (onCancel || onDone)();
          break;
        default:
        }
      }}
      onClick={() => {
        (onCancel || onDone)();
      }}
    >
      <div
        style={{
          backgroundColor: '#fff',
          maxHeight: '90%',
          maxWidth: '90%',
          overflow: 'auto',
          padding: 10,
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        ref={modalElement}
      >
        <div>
          { children }
        </div>
        <div style={{ paddingTop: 5 }}>
          <button onClick={onDone}>
            { doneLabel }
          </button>
          { onCancel && (
            <button onClick={onCancel}>
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
