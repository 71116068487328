import React, { useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  DocumentReference,
  doc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import {
  useFirestore,
  useFirestoreDoc,
  useFunctions,
} from 'reactfire';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

import { useUserState } from './user';
import { Group, Meeting, MemberResponse, responseLabels } from './common/firestoreTypes';
import { ChangeMeetingLocationRequest, MeetingResponseRequest } from './common/functionsTypes';
import Loading from './Loading';
import UserInfo from './UserInfo';
import { showError, showPrompt } from './modal';

function isUrl(s: string | undefined) {
  if (!s) {
    return false;
  }
  try {
    // eslint-disable-next-line no-new
    new URL(s);
    return true;
  } catch {
    return false;
  }
}

// If s is a URL, return it. Otherwise, return a Google Maps search URL.
function locationUrl(s: string | undefined) {
  if (!s) {
    return '';
  }
  return isUrl(s) ? s : `https://www.google.com/maps/search/${encodeURIComponent(s)}`;
}

function AddToCalendar({ meeting }: { meeting: Meeting }) {
  const model = useUserState();
  const { loading, userState, user } = model;
  const { timeZone = 'UTC' } = userState;

  if (meeting.canceled) {
    return null;
  }
  if (loading) {
    return <Loading />;
  }

  const calendarEvent = meeting.calendarEvents?.[user.uid]?.gcal;
  if (calendarEvent) {
    const { url } = calendarEvent;
    return (
      <div>
        <b>This event has been added to your Google Calendar.</b>
        { url && (
          <div>
            <a href={url} target="_blank" rel="noreferrer">View in Google Calendar</a>
          </div>
        )}
      </div>
    );
  }

  return (
    <AddToCalendarButton
      name={`${meeting.groupName} (Friend Scheduler)`}
      description={`"${meeting.groupName}" hangout time, scheduled by Friend Scheduler!

This is not a shared calendar event; use Friend Scheduler or communicate directly to \
make changes.

View in Friend Scheduler: [url]${window.location.href}|${window.location.href}[/url]`}
      startDate={new Intl.DateTimeFormat('sv-SE', { timeZone, dateStyle: 'short' }).format(meeting.start.toDate())}
      startTime={new Intl.DateTimeFormat('sv-SE', { timeZone, timeStyle: 'short' }).format(meeting.start.toDate())}
      endDate={new Intl.DateTimeFormat('sv-SE', { timeZone, dateStyle: 'short' }).format(meeting.end.toDate())}
      endTime={new Intl.DateTimeFormat('sv-SE', { timeZone, timeStyle: 'short' }).format(meeting.end.toDate())}
      timeZone={timeZone}
      location={meeting.location}
      hideBackground
      hideCheckmark
      pastDateHandling="hide"
      trigger="click"
      options={[
        'Google',
        'Apple',
        'Yahoo',
        'Outlook.com',
        'MicrosoftTeams',
        'Microsoft365',
      ]}
    />
  );
}

function MemberResponses({ meeting }: { meeting: Meeting }) {
  const firestore = useFirestore();
  const groupRef = doc(firestore, `groups/${meeting.groupId}`) as DocumentReference<Group>;
  const { data: groupSnap, status } = useFirestoreDoc(groupRef);
  const group = groupSnap?.data();
  if (status === 'loading' || !group) {
    return <Loading />;
  }

  return (
    <div>
      <h3>Responses</h3>
      { group.members.map((member) => (
        ('uid' in member) && (
          <div key={member.uid} className="flex flex-row items-center">
            <UserInfo user={member} />
            :&nbsp;
            { responseLabels[meeting.responses?.[member.uid] || 'no response'] }
          </div>
        )
      ))}
    </div>
  );
}

type MeetingProps = {
  userId: string;
};

function NormalMeetingPage({ userId }: MeetingProps) {
  const { meetingId } = useParams();
  const firestore = useFirestore();
  const model = useUserState();
  const { userState } = model;
  const functions = useFunctions();
  const meetingRef = doc(firestore, `meetings/${meetingId}`) as DocumentReference<Meeting>;
  const { data: meetingSnap, status } = useFirestoreDoc(meetingRef);
  const meeting = meetingSnap?.data();
  const [updating, setUpdating] = useState(false);
  const currentResponse = meeting?.responses?.[userId] || 'no response';

  if (status === 'loading' || updating || !userState) {
    return <Loading />;
  }

  if (status === 'error' || !meetingId || !meeting) {
    return (
      <div>
        <h2>Meeting not found</h2>
      </div>
    );
  }

  // Let them change things any time before the meeting ends.
  const inPast = meeting.end.toDate() < new Date();

  const changeLocation = async () => {
    const newLocation = await showPrompt('New location:', meeting.location);
    if (!newLocation) {
      return;
    }
    const changeIt = httpsCallable<ChangeMeetingLocationRequest>(functions, 'changeMeetingLocation');
    try {
      setUpdating(true);
      await changeIt({ meetingId, newLocation });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error updating location:', error);
      showError(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const responses = Object.values(meeting.responses || {});

  const canceledReason = () => {
    if (!meeting.canceled) {
      return null;
    }
    const decliners = responses.filter(
      (response) => response === 'skip' || response === 'reschedule',
    ).length;
    if (decliners === 1) {
      return 'one of you declined';
    }
    if (decliners === 2) {
      return 'you both declined';
    }
    if (decliners === meeting.membersUids.length) {
      // possible future in which meetings can have >2 people
      return 'everyone declined';
    }
    if (responses.length < meeting.membersUids.length) {
      return 'someone never responded';
    }
    return '... actually I have no idea why';
  };

  const respond = (response: MemberResponse) => async () => {
    const confirmMeeting = httpsCallable<MeetingResponseRequest>(
      functions,
      'confirmMeeting',
    );
    try {
      setUpdating(true);
      await confirmMeeting({ meetingId, userId, response });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error updating response:', error);
      showError(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const confirmed = responses.length === meeting.membersUids.length
    && responses.every((response) => response === 'yes');

  const gcalIntegration = userState.integrations?.googleCalendar;

  return (
    <div>
      { (currentResponse === 'reschedule' || currentResponse === 'skip') && (
        <>
          <br />
          <p>
            ⚠️ Apparently Friend Scheduler <b>chose a bad time for you.</b> You
            might want to check your <Link to="/settings">schedule settings</Link> or
            your <Link to="/gcal">calendar integration settings</Link>.
          </p>
          <br />
        </>
      )}
      { (gcalIntegration?.integrated
          && !gcalIntegration?.scopes?.includes('https://www.googleapis.com/auth/calendar.app.created')) && (
        <>
          <br />
          <p>
            ⚠️ Friend Scheduler can automatically add events to Google Calendar if
            you give it an optional permission in your <Link to="/gcal">calendar integration settings</Link>.
          </p>
          <br />
        </>
      )}
      <h2>
        Meeting
        { meeting.canceled && (
          <>
            <b className="text-red-600"> Canceled</b> because <b>{canceledReason()}</b>
            <br />
          </>
        )}
        { confirmed && (<b> Confirmed</b>) }
        { !confirmed && !meeting.canceled && ' Scheduled'}
      </h2>
      <b>Group:</b>&nbsp;
      <Link to={`/group/${meeting.groupId}`}>
        { meeting.groupName }
      </Link><br />
      <b>Time:</b> { meeting.start.toDate().toLocaleString() }&nbsp;
      to { meeting.end.toDate().toLocaleTimeString() }<br />
      <b>Location: </b>
      <a href={locationUrl(meeting.location)} target="_blank" rel="noreferrer">
        { meeting.location || 'TBD' }
      </a>
      &nbsp;
      <button
        onClick={changeLocation}
        disabled={inPast}
      >
        Change
      </button>
      <br />
      <MemberResponses meeting={meeting} />
      <br />
      <AddToCalendar meeting={meeting} />
      { !inPast && (
        <>
          <br />
          <h2>Change your response</h2>
          <button
            className="bg-lime-800"
            onClick={respond('yes')}
            disabled={currentResponse === 'yes'}
          >
            Yes!
          </button>
          <button
            className="bg-red-800"
            onClick={respond('skip')}
            disabled={currentResponse === 'skip'}
          >
            No, reschedule
          </button>
          <p className="text-sm">
            Can&apos;t make it? No need to explain why; they&apos;ll understand. And
            please understand if they can&apos;t make it. The idea is to make this as
            easy as possible!
          </p>
        </>
      )}
    </div>
  );
}

let runOnce = false;

export default function MeetingPage({ userId }: MeetingProps) {
  const { meetingId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const functions = useFunctions();
  const invitedUserId = searchParams.get('user');
  const response = searchParams.get('confirm') as MemberResponse | null;

  useEffect(() => {
    if (meetingId && invitedUserId && response && !runOnce) {
      runOnce = true;
      const confirmIt = async () => {
        const confirmMeeting = httpsCallable<MeetingResponseRequest>(
          functions,
          'confirmMeeting',
        );
        try {
          await confirmMeeting({ meetingId, userId: invitedUserId, response });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error('Error confirming meeting:', error);
          showError(error.message);
        }
        navigate(`/meeting/${meetingId}`, { replace: true });
      };
      confirmIt();
    }
  }, [functions, invitedUserId, meetingId, navigate, response]);

  if (invitedUserId && response) {
    return <Loading />;
  }

  return <NormalMeetingPage userId={userId} />;
}
