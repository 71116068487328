import React from 'react';
import { DocumentReference, doc } from 'firebase/firestore';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { SchedulingRun } from '../common/firestoreTypes';
import { useParams } from 'react-router-dom';
import Loading from '../Loading';

export default function SchedulingRunDetail() {
  const { runId } = useParams();
  const firestore = useFirestore();
  const { data: run } = useFirestoreDocData<SchedulingRun>(
    doc(firestore, `schedulingRuns/${runId}`) as DocumentReference<SchedulingRun>,
  );

  if (!run) {
    return <Loading />;
  }

  return (
    <div>
      <h2>Scheduling run</h2>
      <h4>Dry run: { run.dryRun ? 'Yes' : 'No' }</h4>
      <h4>Start time: { run.start.toDate().toLocaleString() }</h4>
      <h4>End time: { run.end.toDate().toLocaleString() }</h4>
      <h4>Duration: { (run.end.toMillis() - run.start.toMillis()) / 1000 } seconds</h4>
      { run.exception && (
        <h4>
          <span style={{ color: '#a00' }}>
            <h4>Exception: { run.exception }</h4>
          </span>
        </h4>
      )}
      <h4>Log:</h4>
      <pre>
        { run.log }
      </pre>
    </div>
  );
}
