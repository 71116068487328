import React, { ChangeEvent } from 'react';

import { WeeklyTime, WeeklyTimeRange } from './common/firestoreTypes';
import { shortDayString, weeklyTimeRangeDurationString } from './common/timeUtil';

function sameOrNextDay(
  day: number,
  firstHour: number,
  firstMinute: number,
  secondHour: number,
  secondMinute: number,
): WeeklyTime {
  const laterTime = (firstHour === secondHour)
    ? (secondMinute > firstMinute)
    : (secondHour > firstHour);
  return {
    day: laterTime ? day : ((day + 1) % 7),
    hour: secondHour,
    minute: secondMinute,
  };
}

function parseHourMinute(time: string) {
  const [hourStr, minuteStr] = time.split(':');
  const hour = Number(hourStr);
  const minute = Number(minuteStr);
  return { hour, minute };
}

type WeeklyTimeRangeEditorProps = {
  value: WeeklyTimeRange,
  onChange: (newRange: WeeklyTimeRange) => void,
  title: string,
};

export default function WeeklyTimeRangeEditor({
  value: range,
  onChange,
  title,
}: WeeklyTimeRangeEditorProps) {
  const { start, end } = range;
  const { day } = start;

  const onStartChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { hour, minute } = parseHourMinute(event.target.value);
    if (isNaN(hour) || isNaN(minute)) {
      return;
    }
    onChange({
      start: { day, hour, minute },
      end: sameOrNextDay(day, hour, minute, end.hour, end.minute),
    });
  };

  const onEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { hour, minute } = parseHourMinute(event.target.value);
    if (isNaN(hour) || isNaN(minute)) {
      return;
    }
    onChange({
      start,
      end: sameOrNextDay(day, start.hour, start.minute, hour, minute),
    });
  };

  return (
    <>
      <h2 className="pb-2">{ title }</h2>
      <input
        type="time"
        value={`${String(start.hour).padStart(2, '0')}:${String(start.minute).padStart(2, '0')}`}
        onChange={onStartChange}
      />
      &ensp;
      { shortDayString[range.start.day] }
      <br />
      to
      <br />
      <input
        type="time"
        value={`${String(end.hour).padStart(2, '0')}:${String(end.minute).padStart(2, '0')}`}
        onChange={onEndChange}
      />
      &ensp;
      { shortDayString[range.end.day] }
      <br />
      ({ weeklyTimeRangeDurationString(range) })
    </>
  );
}
