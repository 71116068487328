import React from 'react';
import { useLocation } from 'react-router-dom';
import { getAuth, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth';

export default function SignInPage() {
  const location = useLocation();
  const signInWithGoogle = () => {
    signInWithRedirect(getAuth(), new GoogleAuthProvider());
  };

  return (
    <div className="flex h-screen bg-slate-200">
      <div className="m-auto max-w-xl bg-white">
        <div className="m-5 center text-center">
          <h1>Friend Scheduler</h1>
          <a href="/about.html" target="_blank">What is Friend Scheduler?</a>
          <br />
          <br />
          <button onClick={signInWithGoogle}>
            Sign in with Google
          </button>
          <br />
          <br />
          { location.pathname.startsWith('/accept/') ? (
            <p>
              After you sign in, your invitation will automatically be accepted.
            </p>
          ) : (
            <p>
              Friend Scheduler is currently available by invitation only. If you sign
              in without an invitation, you will be added to our wait list.
            </p>
          )}
          <p>
            <a href="/privacy.html" target="_blank">Privacy policy</a>
          </p>
          <p>
            <a href="/tos.html" target="_blank">Terms of Service</a>
          </p>
        </div>
      </div>
    </div>
  );
}
