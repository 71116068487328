import React from 'react';
import ReactDOM from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';
import { PostHogProvider } from 'posthog-js/react';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render((
  <React.StrictMode>
    <PostHogProvider apiKey={'phc_A7sedXKVk1FRpZw1uFKJyU2fWOP6OE6lk23jclvYJuG'}>
      <FirebaseAppProvider firebaseConfig={config.firebase}>
        <App />
      </FirebaseAppProvider>
    </PostHogProvider>
  </React.StrictMode>
));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
